<template>
  <div id="paginaTipologiaVendita">
    <!-- Elementi Pagina----------------------------------------------------------------------------------- -->
    <b-row class="match-height">
      <!-- Card Grid View: GridElencoTipologiaVendita -->
      <b-col xl="12" md="12">
        <b-card tag="article" class="mb-2">
          <b-card-header class="align-items-baseline">
            <div>
              <b-card-title>Elenco Tipologia Vendita</b-card-title>
              <b-card-text class="mb-0"> Centro Componenti TV </b-card-text>
            </div>
            <!-- dropdown menù laterale dx -->
            <b-dropdown
              variant="link"
              no-caret
              right
              class="chart-dropdown"
              toggle-class="p-0"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="18"
                  class="text-body cursor-pointer"
                />
              </template>
              <b-dropdown-item href="#"> ... </b-dropdown-item>
            </b-dropdown>
          </b-card-header>
          <b-card-body class="mb-0">
            <!-- card body -->
            <b-row>
              <!-- Timer Alert -->
              <b-col xl="6" md="12">
                <b-alert
                  :show="alertADismissCountDown"
                  dismissible
                  variant="warning"
                  @dismissed="alertADismissCountDown = 0"
                  @dismiss-count-down="alertACountDownChanged"
                >
                  <p>Occorre selezionare una riga della tabella</p>
                  <b-progress
                    variant="warning"
                    :max="alertADismissSecs"
                    :value="alertADismissCountDown"
                    height="4px"
                  />
                </b-alert>
              </b-col>

              <b-col xl="12" md="12" class="pt-1 text-center" v-show="loading">
                <b-spinner label="Loading..."></b-spinner>
              </b-col>
              <b-col xl="12" md="12" class="pt-1">
                <ejs-grid
                  id="gridA"
                  ref="gridA"
                  :dataSource="tipologiaVenditaFullData"
                  :allowSorting="true"
                  :sortSettings="gridA.sortOptions"
                  :toolbarClick="gridAClickHandler"
                  :toolbar="gridA.toolbarOptions"
                  :height="gridA.height"
                  :allowFiltering="true"
                  :filterSettings="gridA.filterOptions"
                  :editSettings="gridA.editOptions"
                  :actionBegin="gridAactionBegin"
                  :actionComplete="gridAactionComplete"
                >
                  <e-columns>
                    <e-column
                      field="id"
                      headerText="ID"
                      :isPrimaryKey="true"
                      width="60"
                    ></e-column>
                    <e-column
                      field="codice"
                      headerText="Codice Tipologia Vendita"
                      width="100"
                    ></e-column>
                    <e-column
                      field="descrizione"
                      headerText="Descrizione"
                      width="300"
                    ></e-column>
                    <!-- <e-column
                      field="necessitaStatoContrattoGlobale"
                      headerText="Necessita Stato Contratto Globale"
                      width="100"
                      displayAsCheckBox="true"
                    ></e-column> -->
                  </e-columns>
                </ejs-grid>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <!-- dialogA: dialogDettaglioTipologiaVendita ---------------------------------------------------------------------------------------------------- -->
    <ejs-dialog
      id="dialogA"
      ref="dialogA"
      :header="dialogA.header"
      :buttons="dialogA.buttons"
      :visible="dialogA.visible"
      :showCloseIcon="dialogA.showCloseIcon"
      :close="dialogAClose"
      :open="dialogAOpen"
      :zIndex="dialogA.zIndex"
      :animationSettings="dialogA.animationSettings"
      :isModal="dialogA.isModal"
      v-on:submit.prevent="noop"
    >
      <!-- Content dialog -->
      <b-tabs ref="tabAdialogA" v-model="dialogA.tabAdialogAIndex">
        <b-tab
          title="Informazioni Tipologia Vendita"
          @click="tabClicked('dialogATab01')"
        >
          <b-row class="match-height">
            <!-- Card con Elementi Input: anagrafica -->
            <b-col xl="12" md="12">
              <b-card tag="article" class="mb-2">
                <b-card-header class="align-items-baseline">
                  <div>
                    <b-card-title>Dati Tipologia Vendita</b-card-title>
                    <b-card-text class="mb-0" />
                  </div>
                </b-card-header>
                <b-card-body class="mb-0">
                  <!-- card body -->
                  <b-row>
                    <!-- Input di tipo testo: tipologiaVenditaActiveData.id  -->
                    <b-col xl="12" md="12" class="mb-2">
                      <label for="id" class="h5">Id</label>
                      <ejs-textbox
                        id="id"
                        v-model="tipologiaVenditaActiveData.id"
                        floatLabelType="Never"
                        cssClass=""
                        placeholder="id"
                        showClearButton="true"
                        type="text"
                        autocomplete="false"
                        :readonly="true"
                      />
                    </b-col>

                    <!-- Input di tipo testo: tipologiaVenditaActiveData.codice  -->
                    <b-col xl="12" md="12" class="mb-2">
                      <label for="codice" class="h5"
                        >Codice Tipologia Vendita</label
                      >
                      <ejs-textbox
                        id="codice"
                        v-model="tipologiaVenditaActiveData.codice"
                        floatLabelType="Never"
                        cssClass=""
                        placeholder="Codice Tipologia Vendita"
                        showClearButton="true"
                        type="text"
                        autocomplete="false"
                      />
                    </b-col>

                    <!-- Input di tipo testo: tipologiaVenditaActiveData.descrizione  -->
                    <b-col xl="12" md="12" class="mb-2">
                      <label for="descrizione" class="h5">Descrizione</label>
                      <ejs-textbox
                        id="descrizione"
                        v-model="tipologiaVenditaActiveData.descrizione"
                        floatLabelType="Never"
                        cssClass=""
                        placeholder="Descrizione"
                        showClearButton="true"
                        type="text"
                        autocomplete="false"
                      />
                    </b-col>

                    <!-- Input di tipo checkbox: tipologiaVenditaActiveData.necessitaStatoContrattoGlobale -->
                    <b-col xl="12" md="12" class="mb-2 align-items-center">
                      <b-row class="m-0">
                        <label for="necessitaStatoContrattoGlobale" class="h5 mr-1"
                          >Necessita Stato Contratto Globale</label
                        >
                        <ejs-checkbox
                          id="necessitaStatoContrattoGlobale"
                          v-model="tipologiaVenditaActiveData.necessitaStatoContrattoGlobale"
                          :checked="tipologiaVenditaActiveData.necessitaStatoContrattoGlobale"
                          name="necessitaStatoContrattoGlobale"
                        />
                      </b-row>
                    </b-col>

                    <!-- Input di tipo checkbox: tipologiaVenditaActiveData.necessitaStatoContrattoTV -->
                    <b-col xl="12" md="12" class="mb-2 align-items-center">
                      <b-row class="m-0">
                        <label for="necessitaStatoContrattoTV" class="h5 mr-1"
                          >Necessita Stato Contratto TV</label
                        >
                        <ejs-checkbox
                          id="necessitaStatoContrattoTV"
                          v-model="tipologiaVenditaActiveData.necessitaStatoContrattoTV"
                          :checked="tipologiaVenditaActiveData.necessitaStatoContrattoTV"
                          name="necessitaStatoContrattoTV"
                        />
                      </b-row>
                    </b-col>

                    <!-- Input di tipo checkbox: tipologiaVenditaActiveData.necessitaStatoContrattoTelco -->
                    <b-col xl="12" md="12" class="mb-2 align-items-center">
                      <b-row class="m-0">
                        <label for="necessitaStatoContrattoTelco" class="h5 mr-1"
                          >Necessita Stato Contratto Telco</label
                        >
                        <ejs-checkbox
                          id="necessitaStatoContrattoTelco"
                          v-model="tipologiaVenditaActiveData.necessitaStatoContrattoTelco"
                          :checked="tipologiaVenditaActiveData.necessitaStatoContrattoTelco"
                          name="necessitaStatoContrattoTelco"
                        />
                      </b-row>
                    </b-col>


                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </ejs-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import useJwt from "@/auth/jwt/useJwt";
// import bootstrap-Vue
import {
  LayoutPlugin,
  TabsPlugin,
  CardPlugin,
  DropdownPlugin,
  LinkPlugin,
  AlertPlugin,
  ProgressPlugin,
  ToastPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormCheckboxPlugin,
  FormSelectPlugin,
  FormRatingPlugin,
  SpinnerPlugin,
} from "bootstrap-vue";
// import Syncfusion
import { enableRipple } from "@syncfusion/ej2-base";
import { Query } from "@syncfusion/ej2-data";
// import {
//   extend, addClass, createElement, isNullOrUndefined, detach, EventHandler,
// } from '@syncfusion/ej2-base'
import {
  DateTimePickerPlugin,
  DatePickerPlugin,
  TimePickerPlugin,
  CalendarPlugin,
} from "@syncfusion/ej2-vue-calendars";
import {
  ButtonPlugin,
  CheckBoxPlugin,
  SwitchPlugin,
} from "@syncfusion/ej2-vue-buttons";
import {
  TextBoxPlugin,
  NumericTextBoxPlugin,
  MaskedTextBoxPlugin,
  UploaderPlugin,
} from "@syncfusion/ej2-vue-inputs";
import {
  DropDownListPlugin,
  MultiSelectPlugin,
  ListBoxPlugin,
  CheckBoxSelection,
} from "@syncfusion/ej2-vue-dropdowns";
import { DialogPlugin } from "@syncfusion/ej2-vue-popups";

import {
  GridPlugin,
  ForeignKey,
  Edit as GridEdit,
  Toolbar as GridToolbar,
  Sort as GridSort,
  Filter as GridFilter,
} from "@syncfusion/ej2-vue-grids";
import { DropDownButtonPlugin } from "@syncfusion/ej2-vue-splitbuttons";

const axios = require("axios");
// use bootstrap-vue
Vue.use(TabsPlugin);
Vue.use(CardPlugin);
Vue.use(LayoutPlugin);
Vue.use(DropdownPlugin);
Vue.use(LinkPlugin);
Vue.use(AlertPlugin);
Vue.use(ProgressPlugin);
Vue.use(ToastPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormRatingPlugin);
Vue.use(SpinnerPlugin);
// use Syncfusion
Vue.use(TextBoxPlugin);
Vue.use(NumericTextBoxPlugin);
Vue.use(MaskedTextBoxPlugin);
Vue.use(UploaderPlugin);
Vue.use(DatePickerPlugin);
Vue.use(DateTimePickerPlugin);
Vue.use(TimePickerPlugin);
Vue.use(DropDownListPlugin);
Vue.use(MultiSelectPlugin);
Vue.use(ButtonPlugin);
Vue.use(CheckBoxPlugin);
Vue.use(SwitchPlugin);
Vue.use(DialogPlugin);
Vue.use(GridPlugin);
Vue.use(DropDownButtonPlugin);
Vue.use(ListBoxPlugin);
Vue.use(CalendarPlugin);
enableRipple(true);
// api
const apiVueEndPoint = process.env.VUE_APP_API_ENDPOINT;
const apiTipologiaVenditaUrl = "/main/api/tipologiaVendita";

export default Vue.extend({
  components: {},
  data() {
    return {
      // spinner
      loading: false,
      // tmp
      nonMostrare: false, // utile per non cancellare codice in editing
      // generali
      settings: {
        formatoData: { type: "date", format: "dd-MM-yyyy" }, // da usare per grid
        formatoDatepicker: "dd-MM-yyyy", // da usare per i datepicker
        formatoOra: "HH:mm",
        intervalloOra: 15,
        listboxSelectionSettings: { showCheckbox: true },
      },
      // soggetti
      tipologiaVenditaFullData: [], // elenco completo dati TipologiaVendita
      tipologiaVenditaActiveId: 0, // Id TipologiaVendita attivo (0 se nuovo)
      tipologiaVenditaActiveData: {}, // dati completi del TipologiaVendita attivo
      tipologiaVenditaSavedData: {}, // dati completi dell TipologiaVendita attivo
      tipologiaVenditaDefaultData: {}, // dati completi di default per nuovi TipologiaVendita

      tipologiaVenditaXXXFields: { text: "label", value: "val" }, // specifica i campi da estrarre per popolare XXX
      tipologiaVenditaXXXList: [
        // elenco di xxx
        { label: "Label1", val: "value1" },
      ],

      // per alertA
      alertADismissSecs: 3,
      alertADismissCountDown: 0,

      // per dialogA
      dialogA: {
        header: "",
        visible: false,
        showCloseIcon: true,
        buttons: [
          {
            click: this.dialogAOk,
            buttonModel: { content: "OK", isPrimary: true },
          },
          { click: this.dialogACancel, buttonModel: { content: "Cancel" } },
        ],
        animationSettings: { effect: "FadeZoom", duration: 400, delay: 0 }, // { effect: 'None' }, // selezionata per primo livello
        zIndex: 1050,
        tabAdialogAIndex: 0,
        isModal: false,
      },
      // per gridA
      gridAFlag: false,
      gridA: {
        height: 300,
        editOptions: {
          allowEditing: false,
          allowDeleting: true,
        },
        filterOptions: { type: "Excel" },
        toolbarOptions: [
          {
            text: "Nuovo",
            tooltipText: "Inserisci nuova Tipologia Vendita",
            prefixIcon: "e-plus",
            id: "new",
          },
          {
            text: "Apri",
            tooltipText: "Modifica Tipologia Vendita",
            prefixIcon: "e-expand",
            id: "edit",
          },
          {
            text: "Cancella",
            tooltipText: "Cancella Tipologia Vendita",
            prefixIcon: "e-cancel",
            id: "delete",
          },
          "Search",
        ],
        sortOptions: { columns: [{ field: "id", direction: "Ascending" }] },
      },
    };
  },
  created() {
    console.log("Pagina Tipologia Vendita Creata");

    this.loading = true;
    console.log(this.loading);
    // tipologiaVenditaFullData
    // recupero elenco TipologiaVendita dal backend
    if (localStorage.getItem("userData") !== null) {
      console.log("Recupero elenco Tipologia Vendita dal backend");
      useJwt.get(apiTipologiaVenditaUrl).then((d) => {
        console.log("Tipologia Vendita");
        console.log(d.data);
        this.tipologiaVenditaFullData = d.data;
        this.loading = false;
      });
    } else {
      console.log("Non trovo UserData, prova ancora");
    }

    // Recupero/definisco i valori di default delle entità utilizzate nel componente

    // tipologiaVenditaDefaultData ( esplicitato nei pulsanti "Nuovo.." della gridA )
    this.tipologiaVenditaDefaultData = {};
  },

  provide: {
    // per grid
    grid: [GridToolbar, GridSort, GridFilter, GridEdit, ForeignKey],
    // per listBox
    listbox: [CheckBoxSelection],
    // per multiselect
    multiselect: [CheckBoxSelection],
  },
  methods: {
    // per dialogA
    dialogAOpen(args) {
      console.log("Apertura dialogA");
      // assegnare le informazioni da visualizzare nel dialog A
      console.log("Tipologia Vendita attivo");
      console.log(this.tipologiaVenditaActiveId);
      this.tipologiaVenditaSavedData = { ...this.tipologiaVenditaActiveData };
      // evita chiusura dialog su pressione enter
      let dialogObj = args.element.ej2_instances[0];
      dialogObj.keyDown = this.noop.bind(this);
      dialogObj.dataBind();
    },
    dialogAClose() {
      console.log("Chiusura dialogA");
      this.dialogA.tabAdialogAIndex = 0;
      console.log(this.tipologiaVenditaActiveData);
    },
    dialogAOk() {
      console.log("Cliccato bottone OK di dialogA");
      // salvataggio informazioni in remoto
      if (this.tipologiaVenditaActiveId === 0) {
        // salvataggio in caso di new
        useJwt
          .post(apiTipologiaVenditaUrl, this.tipologiaVenditaActiveData)
          .then((res) => {
            //console.log(res.status);
            this.tipologiaVenditaActiveId = res.data.id;
            return this; // restituisci contesto per permettere aggiornamento grid
          })
          .then((context) => {
            useJwt.get(apiTipologiaVenditaUrl).then((res) => {
              console.log(
                "Aggiornamento gridA con l'aggiunta di una Tipologia Vendita"
              );
              context.tipologiaVenditaFullData = res.data;
              context.tipologiaVenditaFullData = [
                ...context.tipologiaVenditaFullData,
              ];
            });
            // nascondi dialog solo se creazione avvenuta con successo
            context.$refs.dialogA.hide();
          })
          .catch((error) => {
            // costruzione messaggio di errore da mostrare
            const obj = JSON.parse(error.response.request.response);
            const message = Object.keys(obj)[0] + ": " + Object.values(obj)[0];
            this.makeToast("primary", "ERRORE", message);
            console.log(Object.keys(obj)[0]);
            console.log(Object.values(obj)[0]);
          });
      } else {
        // salvataggio in caso di edit
        const editUrl = `${apiTipologiaVenditaUrl}/${this.tipologiaVenditaActiveId}`;
        // qui devo fare caricamento dati ma non include i file da gestire con upload
        useJwt
          .patch(editUrl, this.tipologiaVenditaActiveData)
          .then((res) => {
            console.log(res.status);
            return this; // restituisci contesto per permettere aggiornamento gridA
          })
          .then((context) => {
            useJwt.get(apiTipologiaVenditaUrl).then((res) => {
              console.log(
                "Aggiornamento gridA con la modifica di una Tipologia Vendita"
              );
              context.tipologiaVenditaFullData = res.data;
              context.tipologiaVenditaFullData = [
                ...context.tipologiaVenditaFullData,
              ];
            });
            // nascondi dialog solo se creazione avvenuta con successo
            context.$refs.dialogA.hide();
          })
          .catch((error) => {
            // costruzione messaggio di errore da mostrare
            // TODO: possibili errori multipli
            const obj = JSON.parse(error.response.request.response);
            const message = Object.keys(obj)[0] + ": " + Object.values(obj)[0];
            this.makeToast("primary", "ERRORE", message);
            //console.log(error.response.data.message || error.message)
            console.log(Object.keys(obj)[0]);
            console.log(Object.values(obj)[0]);
          });
      }
      // triggerare aggiornamento filtering / ordinamento? <----------------------
    },
    dialogACancel() {
      console.log("Cliccato bottone Cancel di dialogA");
      this.$refs.dialogA.hide();
    },
    // per gridA
    gridAClickHandler(args) {
      // pulsante NEW
      if (args.item.id === "new") {
        this.dialogA.header = "Nuova Tipologia Vendita";
        this.tipologiaVenditaDefaultData = {
          id: 0,
          codice: null,
          descrizione: null,
          necessitaStatoContrattoGlobale: false,
          necessitaStatoContrattoTV: false,
          necessitaStatoContrattoTelco: false,
        };
        this.tipologiaVenditaActiveData = this.tipologiaVenditaDefaultData;
        console.log("Premuto pulsante creazione nuova Tipologia Vendita da gridA");
        this.tipologiaVenditaActiveId = 0;
        this.$refs.dialogA.show(true);
      }
      // pulsante EDIT
      else if (args.item.id === "edit") {
        console.log("Premuto pulsante modifica Tipologia Vendita da gridA");
        try {
          const selectedRow = this.$refs.gridA.getSelectedRecords();
          let found = false;
          this.tipologiaVenditaActiveId = selectedRow[0].id;
          console.log(this.tipologiaVenditaActiveId);
          for (const i in this.tipologiaVenditaFullData) {
            if (
              this.tipologiaVenditaFullData[i].id === this.tipologiaVenditaActiveId
            ) {
              this.tipologiaVenditaActiveData = {
                ...this.tipologiaVenditaFullData[i],
              };
              found = true;
              break;
            }
          }
          console.log("Tipologia Vendita CORRENTE");
          console.log(this.tipologiaVenditaActiveData);
          console.log(this.tipologiaVenditaActiveId);

          if (found) {
            console.log("Edit TipologiaVendita");
            this.dialogA.header = "Tipologia Vendita";
            this.$refs.dialogA.show(true);
          } else {
            console.log("Elemento non trovato");
          }
        } catch (err) {
          console.log("Occorre selezionare una riga della tabella");
          this.alertAShow();
        }
      }
      // Pulsante DELETE
      else if (args.item.id === "delete") {
        try {
          console.log("Premuto pulsante elimina Tipologia Vendita da gridA");
          const selectedRow = this.$refs.gridA.getSelectedRecords();
          const deletedElementId = selectedRow[0].id;

          const deleteUrl = `${apiTipologiaVenditaUrl}/${deletedElementId}`;
          this.$refs.gridA.ej2Instances.deleteRecord();

          useJwt.delete(deleteUrl).then((res) => {
            console.log("Eliminazione avvenuta con successo");
          });
        } catch (err) {
          console.log(err);
          console.log("Occorre selezionare una riga della tabella");
          this.alertAShow();
        }
      }
    },
    gridAactionBegin(e) {
      if (!this.gridAFlag) {
        // Add and edit operations
        if (e.requestType === "save") {
          const editedData = e.data;
          console.log(editedData);
          // The default edit operation is cancelled
          e.cancel = true;
          const editUrl = `${apiTipologiaVenditaUrl}/${editedData.id}`;
          useJwt.patch(editUrl, editedData).then((res) => {
            console.log(res);
            if (res.status === 200) {
              // se tutto OK
              // Flag is enabled to skip this execution when grid ends add/edit
              this.gridAFlag = true;
              // If your data is updated at the server side successfully, we can call the grid endEdit() method to save the edited data to the Grid
              this.$refs.gridA.endEdit();
            } else {
              this.makeToast(
                "primary",
                "Attenzione",
                "Salvataggio Tipologia Vendita non riuscito"
              );
            }
          });
        }
      }
    },
    // Grid’s actionComplete event handler
    // Triggers after an action is completed
    gridAactionComplete(e) {
      if (e.requestType === "save") {
        // The flag is disabled after operation is successfully performed so that it can enter the condition on next execution
        this.gridAFlag = false;
      }
    },
    // per click tabs
    tabClicked(args) {
      switch (args) {
        case "dialogATab01":
          console.log("Sei nel primo tab del dialogA");
          break;
        case "dialogATab02":
          console.log("Sei nel secondo tab del dialogA");
          break;
        default:
          console.log(args);
      }
    },
    // per alert
    alertACountDownChanged(alertADismissCountDown) {
      this.alertADismissCountDown = alertADismissCountDown;
    },
    alertAShow() {
      this.alertADismissCountDown = this.alertADismissSecs;
    },
    // per toast
    makeToast(variant = null, title = "titolo", message = "messaggio") {
      this.$bvToast.toast(message, {
        title,
        variant,
        solid: true,
        autoHideDelay: 10000,
      });
    },
    noop() {
      console.log("premuto enter");
    },
  },
  computed: {},
});

/* eslint-disable global-require */
</script>

<style lang="scss">
@import "~@syncfusion/ej2-base/styles/material.css";
@import "~@syncfusion/ej2-buttons/styles/material.css";
@import "~@syncfusion/ej2-vue-buttons/styles/material.css";
@import "~@syncfusion/ej2-calendars/styles/material.css";
@import "~@syncfusion/ej2-calendars/styles/datepicker/material.css";
@import "~@syncfusion/ej2-dropdowns/styles/material.css";
@import "~@syncfusion/ej2-vue-popups/styles/material.css";
@import "~@syncfusion/ej2-vue-navigations/styles/material.css";
@import "~@syncfusion/ej2-inputs/styles/material.css";
@import "~@syncfusion/ej2-vue-layouts/styles/material.css";
@import "~@syncfusion/ej2-vue-richtexteditor/styles/material.css";
@import "~@syncfusion/ej2-splitbuttons/styles/material.css";
@import "~@syncfusion/ej2-grids/styles/material.css";
@import "~@syncfusion/ej2-vue-spreadsheet/styles/material.css";
@import "~@syncfusion/ej2-schedule/styles/material.css";
@import "~@syncfusion/ej2-vue-documenteditor/styles/material.css";
@import "~@syncfusion/ej2-treegrid/styles/material.css";
@import "~@syncfusion/ej2-vue-gantt/styles/material.css";
@import "~@syncfusion/ej2-vue-diagrams/styles/material.css";
@import "~@syncfusion/ej2-vue-kanban/styles/bootstrap.css";

.e-btn-icon.e-open-icon {
  background: url("~@/assets/images/svg/open2-svgrepo-com.svg");
  height: 18px;
  width: 18px;
}

.e-btn-icon.e-delete-icon {
  background: url("~@/assets/images/svg/garbage-svgrepo-com.svg");
  height: 18px;
  width: 18px;
}

.e-btn-icon.e-download-icon {
  background: url("~@/assets/images/svg/download2-svgrepo-com.svg");
  height: 18px;
  width: 18px;
}

.e-btn-icon.e-search-icon {
  background: url("~@/assets/images/svg/search-svgrepo-com.svg");
  height: 18px;
  width: 18px;
}

.e-btn-icon.e-upload-icon {
  background: url("~@/assets/images/svg/upload-svgrepo-com.svg");
  height: 18px;
  width: 18px;
}

.e-btn-icon.e-add-icon {
  background: url("~@/assets/images/svg/add2-svgrepo-com.svg");
  height: 18px;
  width: 18px;
}
</style>
